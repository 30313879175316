.tikkie-payment-modal {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  @include media("<=phone") {
    width: 100%;
  }
  .icon {
    height: 40px;
    width: 40px;
    margin-bottom: 12px;
    color: $color-primary;
  }
  .title {
    font-size: 16px;
    color: $color-black;
    text-align: center;
  }
}
