.stripe-payment {
  padding: 0px 0px 54px 0px;
  position: relative;
  @include media("<=sm") {
    padding: 0px 0px 30px 0px;
  }

  .stripe-container {
    display: grid;
    place-items: center;

    .sub-total-blk {
      display: flex;
      align-items: center;
      background: $color-white;
      box-shadow: $shadow-md;
      border-radius: 12px;
      padding: 22px 22px;
      @include media("<=sm") {
        flex-direction: column;
        align-items: flex-start;
      }
      .meta {
        display: flex;
        flex-direction: column;
        flex: 1;
        @include media("<=sm") {
          margin-bottom: 10px;
        }
        .title {
          font-size: 14px;
          color: $color-black;
          margin-bottom: 6px;
        }
        .price {
          font-size: 22px;
          color: $color-black;
          font-weight: 600;
        }
      }
      .rit {
        display: flex;
        align-items: center;
        @include media("<=sm") {
          width: 100%;
        }
        .next-btn {
          padding: 6px 18px;
          font-size: 18px;
          min-width: 90px;
          display: flex;
          align-items: center;
          span {
            display: flex;
            align-items: center;
          }
          @include media("<=sm") {
            width: 100%;
            font-size: 18px;
          }
          svg {
            height: 22px;
            width: 22px;
            margin-left: 5px;
            display: flex;
          }
        }
      }
    }
  }

  .stripe-form {
    padding: 0px 0px 30px 0px;
    @include media("<=sm") {
      padding: 0px 0px 30px 0px;
      display: grid;
    }
  }

  form {
    width: 30vw;
    min-width: 570px;
    @include media("<=sm") {
      min-width: 91vw;
    }
  }

  .form-container {
    background: $color-white;
    box-shadow: $shadow-md;
    border-radius: 12px;
    padding: 24px;
    @include media("<=sm") {
      padding: 16px;
    }
  }

  .card-element-base {
    color: $color-black-900;
    width: 100%;
    padding: 10px 14px;
    border-radius: 10px;
    border: 1px outset $color-primary;
  }

  .card-element-focus {
    outline: 0;
    border-color: $color-primary;
    box-shadow: 0 0 0 0.1rem $color-primary;
    transition: all 0.15s ease;
  }

  #payment-message {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
  }

  //   #payment-element {
  //     /* margin-bottom: 24px; */
  //   }

  /* Buttons and links */
  .pay-btn-container {
    display: grid;
    place-items: center;
  }

  .pay-btn {
    background: $color-primary;
    font-family: Arial, sans-serif;
    color: $color-white;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    min-width: 30%;

    span {
      display: flex;
      justify-content: center;
      align-items: center;

      .icon-container {
        display: flex;
        flex-direction: row;
        svg {
          height: 22px;
          width: 22px;
          margin-left: 5px;
          display: flex;
        }
      }
    }

    @include media("<=sm") {
      margin-left: 24px;
      min-width: 100%;
      font-size: 18px;
    }
  }

  .pay-btn:hover {
    filter: contrast(115%);
  }

  .pay-btn:disabled {
    opacity: 0.5;
    cursor: default;
  }

  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }

  .spinner {
    color: $color-white;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }

  .spinner:before,
  .spinner:after {
    position: absolute;
    content: "";
  }

  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: $color-primary;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }

  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: $color-primary;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }

  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
