.booking-confirmation {
  .wrapper {
    width: 570px;
    margin: 0px auto;
    position: relative;
    padding: 60px 0px;
    @include media("<=sm") {
      width: 100%;
    }
    .page-hdr {
      width: 100%;
      display: flex;
      flex-direction: center;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
      @include media("<=sm") {
        margin-bottom: 30px;
      }
      .title {
        font-size: 22px;
        line-height: 24px;
        font-weight: 600;
        color: $color-black;
        letter-spacing: 0.3px;
        span {
          font-weight: 400;
          opacity: 0.5;
        }
      }
    }
  }
}
