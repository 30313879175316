*,
html,
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
label,
fieldset,
input,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
fieldset,
img {
  border: 0;
}
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}
ol,
ul,
li {
  list-style: none;
}
caption,
th {
  text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}
div,
img,
button,
input,
textarea,
select {
  outline: none;
}
strong {
  font-weight: bold;
}
em {
  font-style: italic;
}
a,
img,
button,
input,
textarea {
  border: none;
  outline: none;
  text-decoration: none;
  background: none;
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.word-wrap {
  @include word-wrap;
}

.text-ellipsis {
  @include text-ellipsis;
}

.anim {
  @include anim;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}

button {
  all: unset;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.holder {
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #ddd 8%, #e9e9e9 18%, #ddd 33%);
  background-size: 1200px 100%;
}
@-webkit-keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}
@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}

::-webkit-scrollbar {
  width: 9px;
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.15);
}

.hidden-element {
  position: absolute;
  left: -88784352520;
  top: -88784352520;
  display: none;
}

.hide {
  @include hide;
}
.show {
  @include show;
}

.text-gradient {
  @include text-gradient;
}

.checkbox {
  height: 18px;
  width: 18px;
  min-width: 18px;
  border: 1px solid rgba($color-black, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  @include anim(0.2s);
  cursor: pointer;
  overflow: hidden;
  position: relative;
  svg {
    color: $color-white;
    height: 19px;
    width: 19px;
    @include anim(0.2s);
    @include hide;
    z-index: 2;
  }
  &.active {
    background: $color-primary;
    border-color: $color-primary;
    svg {
      @include show;
      color: $color-white;
    }
  }
}

.button-primary {
  height: 40px;
  border-radius: 10px;
  // box-shadow: $shadow;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 14px;
  background: $color-primary;
  color: $color-white;
  font-size: 16px;
  font-weight: 600;
  @include anim;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
  &:disabled {
    background: $color-bg-grey;
    color: $color-grey;
    cursor: no-drop;
    box-shadow: none;
    &:hover {
      opacity: 1;
    }
  }
}

.field-control {
  &:not(:last-child) {
    margin-bottom: 18px;
  }
  .field-lbl {
    font-size: 16px;
    font-weight: 600;
    color: $color-black;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    .star {
      margin-left: 6px;
      color: $color-red;
    }
  }
  .field {
    display: flex;
    align-items: center;
    padding: 10px 0px;
    border: 2px solid $border-color-1;
    height: 48px;
    border-radius: 10px;
    @include anim;
    &:focus-within {
      border-color: $color-blue;
    }
    .input {
      font-size: 16px;
      font-weight: 600;
      color: $color-black;
      padding: 0px 14px;
      width: 100%;
    }
  }
  .invalid-field {
    display: flex;
    align-items: center;
    padding: 10px 0px;
    border: 2px solid $border-color-1;
    height: 48px;
    border-radius: 10px;
    @include anim;
    border-color: $color-red;
    .input {
      font-size: 16px;
      font-weight: 600;
      color: $color-black;
      padding: 0px 14px;
      width: 100%;
    }
  }
  .msg {
    margin-top: 10px;
    font-size: 13px;
    font-weight: 500;
    &.error-msg {
      color: red;
    }
  }
}

.radio-item {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid $color-purple;
  position: relative;
  display: flex;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @include anim;
  &:before {
    content: " ";
    position: absolute;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: $color-purple;
    @include hide;
    @include anim;
  }
  &.active {
    &:before {
      @include show;
    }
  }
}
