.footer-global {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    color: $color-grey;

    a {
        margin: 0 4px;
        color: $color-primary;
    }
}