/* Spinner Circle Rotation */
.sp-circle {
  width: 34px;
  height: 34px;
  clear: both;
  border-width: 4px;
  border-color: rgba($color-white, 0.7);
  border-style: solid;
  border-top-width: 4px;
  border-top-color: rgba($color-primary, 1);
  border-radius: 50%;
  -webkit-animation: spCircRot 0.6s infinite linear;
  animation: spCircRot 0.6s infinite linear;
}
@-webkit-keyframes spCircRot {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@keyframes spCircRot {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
