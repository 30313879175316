@mixin anim($duration: 0.3s) {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: $duration;
}

@mixin word-wrap {
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 99%;
}

@mixin text-ellipsis($lines: 1) {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 99%;
  @if ($lines > 1) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
  } @else {
    white-space: nowrap;
  }
}

@mixin bg-image($radius: 0px) {
  height: 100%;
  width: 100%;
  border-radius: $radius;
  background-color: #070321 !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

@mixin hide {
  opacity: 0;
  visibility: hidden;
}

@mixin show {
  opacity: 1;
  visibility: visible;
}

@mixin dropDownAnim($duration: 0.2s) {
  animation-name: drop;
  animation-duration: $duration;
  transform-origin: top;
}

@keyframes drop {
  0% {
    transform: scaleY(0.5);
    opacity: 0;
  }
}

@mixin text-gradient(
  $color: linear-gradient(180deg, #fda7ff -8.18%, #ad76ff 110%)
) {
  background: $color;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

@mixin scrollbar-style {
  &::-webkit-scrollbar {
    width: 4px;
    background: rgba($color-black-3, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background: $color-black-3;
  }
}
