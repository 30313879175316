.splash-screen {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .logo {
    height: 133px;
    margin-bottom: 64px;
  }
}
