.error-alert {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: $color-white;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: $color-white;
  border-radius: 20px;
  @include media("<=phone-sm") {
    width: 250px;
  }
  @include media("<=phone-md") {
    width: 300px;
  }
  @include media("<=phone") {
    width: 350px;
  }

  .title {
    margin-top: 0;
    color: $color-red;
    font-weight: bold;
  }

  .description {
    margin: 15px 0;
    color: $color-red;
    font-weight: 300;
  }

  .action {
    width: 80px;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    background: $color-primary;
    border-radius: 10px;
    color: $color-white;
    font-size: 14px;
  }

  .action-deny {
    width: 80px;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    border: 1px solid $color-primary;
    border-radius: 10px;
    color: $color-primary;
    font-size: 14px;
  }

  .action-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .private-booking-field-control {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .field {
    display: flex;
    align-items: center;
    padding: 10px 0px;
    margin-right: 10px;
    border: 2px solid $border-color-1;
    height: 48px;
    border-radius: 10px;
    @include anim;
    &:focus-within {
      border-color: $color-blue;
    }
    .input {
      font-size: 16px;
      font-weight: 600;
      color: $color-black;
      padding: 0px 14px;
      width: 100%;
    }
  }
}