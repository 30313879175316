$page-background: #f1f2f4;
$background-overlay: rgba(217, 217, 217, 0.6);

$color-white: #fff;
$color-red: #ff5500;

$color-black: #000000;
$color-black-900: #212121;

$color-purple: #7e00dd;

$color-blue: #5265ff;
$color-dark-blue: #373772;

$color-green: rgb(0, 200, 0);

$color-primary: #f64e60;

$color-secondary-500: #584cf4;
$color-secondary-100: #eeedfe;

$color-bg-grey: #f1f2f4;
$color-bg-grey-1: #e3e9f2;

$color-grey: #6e7b8c;
$color-grey-4: #f4f4f4;

$border-color: rgba(0, 0, 0, 0.03);
$border-color-1: #e3e9f2;
$border-color: #bfd1e5;

$shadow: 0px 1px 4px rgba(30, 40, 53, 0.2);
$shadow-md: 0px 1px 4px rgba(0, 0, 0, 0.07),
  0px 6px 16px -8px rgba(0, 0, 0, 0.15);
